<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          {{ $route.params.id == 0 ? "Nuevo movimiento" : "" }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6">
              <span>Usuario</span>
              <v-select label="key" v-model="data.user" :options="userData" style="margin-bottom: 10px;"/>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6">
              <CSelect
                aria-label="Producto"
                v-model="data.item"
                :options="stockData"
              >
                <template #prepend-content
                  ><font-awesome-icon icon="book"
                /></template>
              </CSelect>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6">
              <CInput
                type="number"
                min="1"
                :max="data.maxQuantity"
                placeholder="Cantidad"
                v-model="data.quantity"
              >
                <template #prepend-content
                  ><font-awesome-icon icon="hashtag"
                /></template>
              </CInput>
            </CCol>
          </CRow>

          <CRow class="mb-2">
            <CCol col="12">
              <CButton color="primary" @click="addItem" v-if="!loading">
                Agregar
              </CButton>
              <div v-if="loading">
                <CSpinner variant="grow" size="lg" />
              </div>
            </CCol>
          </CRow>

          <CDataTable hover striped sorter :items="items" :fields="fields">
            <template #actions="data">
              <td class="text-center">
                <CButton
                  color="danger"
                  @click="
                    () => {
                      removeItem(data);
                    }
                  "
                >
                  Eliminar
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CRow class="mt-2">
            <CCol sm="12" md="6">
              <CButton color="primary" @click="goBack" v-if="!loading"
                >Atrás</CButton
              >
            </CCol>
            <CCol sm="12" md="6" class="text-right">
              <CButton color="primary" @click="save" v-if="!loading">
                Guardar
              </CButton>
              <div class="text-right" v-if="loading">
                <CSpinner variant="grow" size="lg" />
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";

const stockResource = new Resource("stock/usersAndStock");
const movementResource = new Resource("stockmovement");
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';


export default {
  name: "Movement",
  components: {
    vSelect
  },
  data() {
    return {
      loading: false,
      toasts: [],
      data: {
        user: null,
        users: [],
        item: null,
        stock: [],
        quantity: 1,
        maxQuantity: 1,
      },
      items: [],
      fields: [
        {
          key: "userName",
          label: "Cliente",
          sorter: true,
          filter: true,
        },
        {
          key: "itemName",
          label: "Producto",
          sorter: true,
          filter: true,
        },
        {
          key: "quantity",
          label: "Cantidad",
          sorter: true,
          filter: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sorter: false,
          filter: false,
        },
      ],
    };
  },

  mounted() {
    this.getUsersAndStock();
  },

  computed: {
    userData() {
      return [
        { key: null, value: "Seleccione" },
        ...this.data.users.map((value, index) => {
          return { key: `(${value.email}) ${value.name}`, value: index };
        }),
      ];
    },
    stockData() {
      return [
        { key: null, value: "Seleccione" },
        ...this.data.stock.map((value, index) => {
          return {
            label: `(${value.qtyonhand}) ${value.ItemName}`,
            value: index,
          };
        }),
      ];
    },
  },

  watch: {
    "data.item": function (newValue, oldValue) {
      const item = this.data.stock[newValue];
      if (item) {
        this.data.maxQuantity = item.qtyonhand;
      }
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    addItem() {
      try {
        if (this.data.user == null) {
          throw "Debe seleccionar un cliente";
        }
        if (this.data.item == null) {
          throw "Debe seleccionar un producto";
        }
        const user = this.data.users[this.data.user.value];
        const item = this.data.stock[this.data.item];
        const quantity = this.data.quantity;
        if (quantity <= 0) {
          throw "La cantidad debe ser mayor a 0";
        }
        if (quantity > item.qtyonhand) {
          throw "La cantidad supera al stock";
        }

        const checkDuplicates = this.items.filter(
          (element) => element.stockFrom == item.id && element.userTo == user.id
        );

        if (checkDuplicates.length > 0) {
          throw "Este producto ya fue agregado para el mismo cliente";
        }

        this.items.push({
          stockFrom: item.id,
          userTo: user.id,
          quantity: quantity,
          itemCode: item.ItemCode,
          itemName: item.ItemName,
          userName: user.name,
        });

        this.data.stock[this.data.item].qtyonhand -= parseInt(quantity);
        this.data.quantity = 1;
      } catch (error) {
        this.toasts.push({
          title: "Error",
          color: "danger",
          message: error,
        });
      }
    },

    removeItem(data) {
      const item = data.item;
      const index = this.data.stock.findIndex(
        (element) => element.id == item.stockFrom
      );
      this.data.stock[index].qtyonhand += parseInt(item.quantity);
      this.items = this.items.filter(
        (element) =>
          `${element.stockFrom}${element.userTo}` !=
          `${item.stockFrom}${item.userTo}`
      );
    },

    async getUsersAndStock() {
      this.loading = true;
      try {
        const response = await stockResource.list();
        if (response.status == 200) {
          this.data.users = response.data.data.users;
          this.data.stock = response.data.data.stock;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      this.loading = true;
      try {
        const response = await movementResource.store({ data: this.items });
        if (response.status == 200 && response.data.status != "ERROR") {
          this.getUsersAndStock();

          this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Movimiento guardado con exito",
          });

          this.items = [];
          this.data.user = null;
          this.data.item = null;
          this.data.quantity = 1;
        } else {
          throw response.data.message;
        }
      } catch (error) {
        console.log(error);
        this.toasts.push({
          title: "Error",
          color: "danger",
          message: error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
